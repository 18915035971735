.container{
    background-color: #FFFFFF;
    .main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2em;
        padding: 30px;

        p{
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.34285715222358704px;
        }

        button{
            width: 100%;
            height: 40px;
            border: none;
            border-radius: 20px;
            background-color: #157AFF;
            color: #FFFFFF;
        }

        .dataInput{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 1.5em;
            :focus{
                outline: none;
                box-shadow: none;
            }

            select{
                width: 100%;
                height: 30px;
                border: none;
                border-bottom: 1px solid #E1E3E7;
            }

            p{
                font-family: Source Sans Pro;
                font-size: 13px;
                font-weight: 400;
                line-height: 12px;
                color: #9CA3AF;
            }

            div{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;
                width: 100%;
                :focus{
                    outline: none;
                    box-shadow: none;
                }
                p{
                    font-family: Source Sans Pro;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 12px;
                    color: #157AFF;
                    border-bottom: 1px solid #157AFF;
                }
                input{
                    width: 100%;
                    height: 30px;
                    border: none;
                    border-bottom: 1px solid #E1E3E7;
                }
            }
        }
    }
}