.container{
    background-color: #FFFFFF;
    .main{
        .cardContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1em;
            
            .filterCard{
                background-color: #85BAFF3D;
                width: 85%;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                position: relative;
                margin-top: 20px;
    
                .filterText{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    text-align: center;
                    color: #157AFF;
                }
    
                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.3em;
                    position: absolute;
                    right: 20px;
    
                    .clearText{
                        font-family: Montserrat;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 15px;
                        letter-spacing: 1.5px;
                        text-align: center;
                        border-bottom: 1px solid black;
                    }
                }
            }

            .introCard{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 85%;
                height: auto;
                border: 1px solid #808080;
                border-radius: 10px;
                margin-bottom: 10px;
                margin-top: 10px;
                padding: 10px;

                p{
                    font-family: Montserrat;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    text-align: left;
                    color: #273240;
                }

                img{
                    height: 25px;
                    width: 22px;
                }
            }
    
            .card{
                width: 85%;
                height: auto;
                border: 1px solid #808080;
                border-radius: 10px;
                margin-bottom: 10px;
                margin-top: 10px;
                .cardHeading{
                    padding: 10px;
                    border-bottom: 1px solid #D1D0D0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .heading1{
                        font-family: Montserrat;
                        color: #808080;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 1.5px;
                        text-align: left;
                    }
                    .heading2{
                        font-family: Montserrat;
                        color: #808080;
                        font-size: 70px;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: 1.5px;
                        text-align: right;
                        margin-bottom: 10px;
                    }
                }

                .cardData{
                    border-bottom: 1px solid #D1D0D0;
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    gap: 2em;

                    .subData1, .subData2{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        max-width: 200px;
                        word-break: break-word;

                        .name, .id{
                            font-family: Montserrat;
                            font-size: 11px;
                            font-weight: 400;
                            line-height: 17px;
                            letter-spacing: 1.5px;
                            text-align: left;
                            color: #273240;
                        }

                        .status{
                            font-family: Montserrat;
                            font-size: 11px;
                            font-weight: 400;
                            line-height: 17px;
                            letter-spacing: 1.5px;
                            text-align: right;
                            color: #808080;
                        }

                        .statusValue{
                            font-family: Montserrat;
                            font-size: 11px;
                            font-weight: 600;
                            line-height: 17px;
                            letter-spacing: 1.5px;
                            text-align: right;
                            color: #157AFF;
                        }

                        .time{
                            font-family: Montserrat;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 15px;
                            letter-spacing: 1.5px;
                            text-align: left;
                            color: #9F9F9F;
                        }
                    }
                }

                .buttonContainer{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1em;
                    margin-top: 20px;
                    margin-bottom: 20px;

                    .minButton{
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 95%;
                        height: 40px;
                        background-color: #157AFF;
                        border-radius: 20px;
                        position: relative;
                        p{
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17px;
                            letter-spacing: 1.5px;
                            text-align: center;
                            color: #FFFFFF;    
                        }   

                        img{
                            position: absolute;
                            right: 20px;
                            transform: rotate(180deg);
                        }
                    }

                    .allButton{
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        width: 95%;
                        height: 40px;
                        background-color: #157AFF;
                        border-radius: 20px;
                        position: relative;
                        p{
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17px;
                            letter-spacing: 1.5px;
                            text-align: center;
                            color: #FFFFFF;    
                        }   

                        img{
                            position: absolute;
                            right: 20px;
                        }
                    }   
                }
            }
    
        }
    }
}