.main {
    background-color: #202020;
    padding: 5px 20px 5px 20px;
    // position: sticky;
    top: 0;
    z-index: 2;
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        div{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;

            .url{
                color: #FFFFFF;
                background-color: #157AFF;
                border-radius: 50%;
                width: 35px;
                height: 35px;
                padding-bottom: 4px;
                p{
                    font-size: 35px;
                }
            }

            .language{
                color: #FFFFFF;
                background-color: #157AFF;
                border-radius: 20px;
                width: 90px;
                height: 35px;

                p{
                    font-size: 16px;
                }
            }
    
            .logo{
                width: 55px;
                height: 45px;
            }
        }
    }
    
    .menu{
        height: auto;
        animation: growDown 100ms ease-in-out forwards;
        p{
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 1.5px;
            color: #FFFFFF;
            text-align: center;
            padding: 13px;
            border-top: 1px solid #808080;
        }

        @keyframes growDown {
            0% {
                transform: scaleY(0)
            }
            100% {
                transform: scaleY(1)
            }
        }
    }

    .filter{
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5em;
        animation: growDown 100ms ease-in-out forwards;

        .filterHeader{
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 1.5px;
            text-align: left;
            color: white;
            padding: 10px;
        }

        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 10px;
            border-top: 1px solid #808080;
            width: 100%;
            gap: 0.5em;

            p{
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 1.5px;
                text-align: left;
                color: white;
            }
            input, select{
                outline: none;
                width: 100%;
                height: 30px;
                border-radius: 10px;
                padding: 5px;
                color:#202020;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 1.5px;
            }
        }

        @keyframes growDown {
            0% {
                transform: scaleY(0)
            }
            100% {
                transform: scaleY(1)
            }
        }

        button{
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 1.5px;
            text-align: center;
            background-color: #157AFF;
            color: #FFFFFF;
            width: 70%;
            height: 40px;
            outline: none;
            border: none;
            border-radius: 10px;
            padding: 5px;
            margin-bottom: 10px;
        }
    }
}
