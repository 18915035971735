.container{
    background-color: #FFFFFF;

    .reportContainer{
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        .back{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            gap: 0.7em;

            p{
                font-family: Montserrat;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0.24285715222358704px;
                text-align: center;
                color: #000000;
            }
        }

        .card{
            background-color: #EDF0F6;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: auto;
            border-radius: 10px;
            .info{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 8px;
                margin-bottom: 2px;
                .inspection, .date, .status, .reason{
                    font-family: Montserrat;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 1.3px;
                    text-align: left;
                    color: #000000;
                }
                .inspectionValue, .dateValue, .statusValue, .reasonValue{
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 1.3px;
                    text-align: right;
                    color: #273240;
                }
                .inspectionValue{
                    color: #157AFF;
                }
                .reasonValue{
                    color: #404852;
                }
            }
            .downloadBtn{
                border: 1px solid #000000;
                border-radius: 20px;
                padding: 5px 20px 5px 20px;
                margin: 7px 10px 10px 10px;
                p{
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 1.5px;
                    text-align: left;
                    color: #273240;
                }
            }
            .type1{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 40px;
                background-color: #C8CFDD;
                padding: 15px;

                .key{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 1.2px;
                    text-align: left;
                    color: #000000;
                }
                .value{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 1.2px;
                    text-align: right;
                    color: #273240;
                }
            }
            .type2{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 40px;
                padding: 15px;

                .key{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 1.2px;
                    text-align: left;
                    color: #000000;
                }
                .value{
                    font-family: Montserrat;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    letter-spacing: 1.2px;
                    text-align: right;
                    color: #273240;
                }
            }
        }
        .menuBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1em;
            .option1, .option2{
                border: 1px solid #000000;
                border-radius: 20px;
                width: 150px;
                height: 33px;
                text-align: center;
                padding-top: 5px;
                
                p{
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 1.2px;
                    color: #010101;
                }   
            }
            .option1{
                margin-left: 10px;
            }
        }
        .imageSec{
            img{
                background-color: #000000;
                width: 60px;
                height: 60px;
                object-fit: contain;
                margin: 5px;
            }
        }
        .damageSec{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 1em;

            .damageCard{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 100%;
                border: 1px solid #000000;
                border-radius: 10px;
                gap: 0.6em;

                .head{
                    background-color: #63728E;
                    width: 100%;
                    height: 40px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    padding: 10px;
                    p{
                        font-family: Montserrat;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 1.5px;
                        text-align: left;
                        color: #FFFFFF;
                    }
                }

                .damageInfoCard{
                    padding: 0px 10px 10px 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 100%;
                    
                    .damageInfo{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 10px;
                        border-bottom: 1px solid #E9E9E9;

                        .damageKey{
                            font-family: Montserrat;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 17px;
                            letter-spacing: 0.34285715222358704px;
                            color: #273240;
                        }
                    }
                }
            }
        }
    }
    .imageView{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        z-index: 5;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        
        .close{
            position: absolute;
            top: 20px;
            right: 20px;
            width: 35px;
        }

        .mainImage{
            object-fit: contain;
            width: 100%;
        }
    }
}