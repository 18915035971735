.container{
    background-color: #FFFFFF;
    
    .typeMain{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5em;
        padding: 20px;

        p{
            font-family: Montserrat;
            font-size: 17px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 1.5px;
            color: #273240;
            text-align: center;
        }

        .optionContainer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5em;
            div{
                padding: 80px;
                border: 1px solid #273240;
                border-radius: 20px;
                p{
                    font-family: Montserrat;
                    font-size: 30px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 1.5px;
                    color: #273240;
                }
            }
        }
    }
}