.container{
    position: absolute;
    top:0px;
    right:0px;
    left:0px;
    background-color: #042F5F;
    display: flex;
    flex-direction: column;
    padding: 40px 10px 0px 10px;
    gap: 2em;

    .titleContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.4em;

        div{
            p{
                color: #A5A5A5;
                text-align: center;
                font-size: 14px;
                line-height: 15.2px;
                letter-spacing: 0.34px;
                font-weight: 400;
            }
        }

    }

    .infoContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1em;

        p{
            color: #FFFFFF;
            font-weight: 600;
            font-size: 16px;
            line-height: 19.5px;
            letter-spacing: 0.34px;
        }

        select{
            width: 80%;
            height: 40%;
            border: #202020;
            border-radius: 20px;
            padding: 10px;
            text-align: center;
            color: #FFFFFF;
            background: #042F5F;
            border: solid 1px #FFFFFF;
            outline: none;
            margin-top: 25px;
        }

        input{
            width: 80%;
            height: 40%;
            border: #202020;
            border-radius: 20px;
            padding: 10px;
            color: #FFFFFF;
            background: #042F5F;
            border: solid 1px #FFFFFF;
            outline: none;
        }
    }

    p{
        color: #A5A5A5;
        text-align: center;
        font-size: 14px;
        line-height: 18.2px;
        letter-spacing: 0.34px;
        font-weight: 400;
        margin-top: 10px;
    }

    span{
        color: #A5A5A5;
        text-align: center;
        font-size: 14px;
        line-height: 18.2px;
        letter-spacing: 0.34px;
        font-weight: 400;
        margin-top: 10px;
        border-bottom: 1px solid #A5A5A5;
    }

    h5{
        color: red;
        text-align: center;
        line-height: 18.2px;
        letter-spacing: 0.34px;
    }

    .loginBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .startImg{
            position: absolute;
            z-index: 2;
            top: 35px;
        }

        .carImg{
            margin-top: 60px;
            margin-right: 20px;
        }
    }
}